/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.pagination {
  float: right;
}

.pagination li {
  padding: 8px 12px;
  margin-top: 5px;
}

.pagination .active {
  font-weight: bold;
  background: #004C97;
  border-radius: 3px;
}

.pagination .active a {
  color: #ffffff;
}
