/* activate toggle */


.accordion-item.active .accordion-button {
  background-color: #f8fafa;
}
.accordion-body {
  overflow: hidden;
  transition:ease 0.2s;
}
.accordion-body.open {
  height: auto;   
  /* height: 100px; */
}

.accordian-head {
  background: #004c97;
  color: #fff;
  padding: 10px 15px;
  border-bottom: 1px solid;
  text-align: left;
}